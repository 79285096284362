import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DatePicker, Select, Button, Table, Input } from 'antd'

import Layout from '../../components/layout/Layout'
import axiosInstance from '../../utils/axiosInstance'

const { RangePicker, Avatar } = DatePicker

const timeZones = [
  {
    value: '-5',
    label: '-5',
  },
  {
    value: '-6',
    label: '-6',
  },
  {
    value: '+5',
    label: '+5',
  },
  {
    value: '+6',
    label: '+6',
  },
]

const timeBlocks = [
  {
    value: '12:01am-4am',
    label: '12:01am-4am',
  },
  {
    value: '4:01am-8am',
    label: '4:01am-8am',
  },
  {
    value: '8:01am-12pm',
    label: '8:01am-12pm',
  },
  {
    value: '12:01pm-4pm',
    label: '12:01pm-4pm',
  },
  {
    value: '4:01pm-8pm',
    label: '4:01pm-8pm',
  },
  {
    value: '8:01pm-12am',
    label: '8:01pm-12am',
  },
]

const categories = [
  {
    value: 'priorities',
    label: 'priorities',
  },
  {
    value: 'habits',
    label: 'habits',
  },
  {
    value: 'challenges',
    label: 'challenges',
  },
  {
    value: 'goals',
    label: 'goals',
  },
]

const focusAreasItems = [
  {
    value: '668b861166b33e2b5723d4ae',
    label: 'Health & Fitness',
  },
  {
    value: '6691dce2cb91bb844f9233e8',
    label: 'Play Experiential',
  },
  {
    value: '6691dd26cb91bb844f9233f9',
    label: 'Professional Development',
  },
  {
    value: '6691dc68cb91bb844f9233bd',
    label: 'People Development',
  },
  {
    value: '668fe9e48cfeda78cee0c6d1',
    label: 'Personal Development',
  },
  {
    value: '668feb968cfeda78cee0c724',
    label: 'Financial Health',
  },
  {
    value: '6691dde9cb91bb844f92342e',
    label: 'Mental Emotional Spiritual',
  },
]

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
  },
  {
    title: 'Activity Frequency (Completed)',
    dataIndex: 'totalCompletedActivities',
    key: 'totalCompletedActivities',
    sorter: (a, b) => a.totalCompletedActivities - b.totalCompletedActivities,
  },
  {
    title: 'Activity Frequency (All)',
    dataIndex: 'totalActivityTaken',
    key: 'totalActivityTaken',
    sorter: (a, b) => a.totalActivityTaken - b.totalActivityTaken,
  },
  {
    title: 'Average Rating',
    dataIndex: 'averageRating',
    key: 'averageRating',
    render: (data) => <div>{data ? <span>{data.toFixed(2)}% +</span> : <span>{0}</span>}</div>,
    sorter: (a, b) => a.averageRating - b.averageRating,
  },
  {
    title: 'Completion %',
    dataIndex: 'percentageCompleted',
    key: 'percentageCompleted',
    render: (data) => <div>{data ? <span>{data.toFixed(2)}% +</span> : <span>{0}</span>}</div>,
    sorter: (a, b) => a.percentageCompleted - b.percentageCompleted,
  },
]

function MemberActivities() {
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [organizations, setOrganizations] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')
  const [timeZone, setTimeZone] = useState(timeZones[0].value)
  const [hourBlock, setHourBlock] = useState('')
  const [category, setCategory] = useState('')
  const [selectedFocusArea, setSelectedFocusArea] = useState('')
  const [activities, setActivities] = useState([])
  const [selectedActivity, setSelectedActivity] = useState('') // id of the Focus Item
  const [data, setData] = useState(null)
  const [calculations, setCalculations] = useState(null)

  const getMyInfo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/admin/me`)

      // Check the admin organizations
      if (response?.data?.user?.organizations && response?.data?.user?.organizations.length > 0) {
        const org = response.data.user.organizations
        // console.log(org)

        // store to render at JSX
        org.forEach((element, idx) => {
          if (idx == 0) setSelectedOrg(element._id) // auto selecting the first organization to fetch some results
          setOrganizations((prevElements) => [
            ...prevElements,
            {
              value: element._id,
              label: element.name,
            },
          ])
        })
      }

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getData = async () => {
    try {
      if (!startDate || !endDate) {
        console.log(startDate, endDate)
        return
      }

      setLoading(true)

      const payload = {
        timeZone,
        startDate,
        endDate,
        summaryOfClub: selectedOrg,
        ...(hourBlock ? { hourBlock } : {}),
        ...(category ? { category } : {}), // e.g. priorities, habits
        ...(selectedFocusArea ? { selectedFocusArea } : {}), // e.g. PER, PLX
        ...(selectedActivity ? { selectedActivity } : {}), // e.g. subtitle
      }

      console.log(payload)

      const response = await axiosInstance.post(`/api/v1/metrics/memberActivities`, payload)

      //   console.log(response.data)
      setData(response.data?.results)
      setCalculations(response.data?.calculations)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleRangePicker = (value) => {
    // console.log('Selected Time: ', value)
    value?.map((el, idx) => {
      // console.log(moment(new Date(el)).format('MMMM DD, YYYY h:mm:ss a')) // Readable format
      // console.log(new Date(el).getTime()) // Epoch timestamp in miliseconds
      let start = 0
      let end = 0
      if (idx == 0) {
        start = new Date(el).getTime()
        setStartDate(start)
      } else if (idx == 1) {
        end = new Date(el).getTime()
        setEndDate(end)
      }
      console.log(start)
      console.log(end)
    })
  }

  const handleSearch = async (val) => {
    if (!val.trim()) {
      return
    }

    try {
      setLoading(true)
      const response = await axiosInstance.post('/api/v1/search/globalSearch', {
        query: val,
      })
      console.log('bye')
      console.log('Success', `Results: ${JSON.stringify(response.data)}`)
      // setActivities(response.data.searchResults)
      const items = response.data.searchResults.map((elem) => {
        return {
          label: `(${elem.category}) ${elem.subTitle} `,
          value: elem._id,
        }
      })
      console.log(items)
      setActivities(items)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getMyInfo()
  }, [])

  if (organizations.length == 0) return null

  return (
    <Layout>
      <h1>Member Activities</h1>
      <RangePicker onChange={handleRangePicker} />
      <Select
        placeholder="Please select club or business"
        style={{ width: 250 }}
        defaultValue={organizations[0].value}
        onChange={(value) => setSelectedOrg(value)}
        options={organizations}
      />
      <Select placeholder="Please select timezone" style={{ width: 100 }} defaultValue={timeZones[0].value} onChange={(value) => setTimeZone(value)} options={timeZones} />
      <Select placeholder="Select a hour block" style={{ width: 200 }} onChange={(value) => setHourBlock(value)} options={timeBlocks} allowClear />
      <Select placeholder="Select a category (Default all)" style={{ width: 300 }} onChange={(value) => setCategory(value)} options={categories} allowClear />
      <Select placeholder="Select a focus area (Default all)" style={{ width: 300 }} onChange={(value) => setSelectedFocusArea(value)} options={focusAreasItems} allowClear />

      <Select style={{ width: 300 }} showSearch filterOption={false} onSearch={handleSearch} options={activities} onChange={(value) => setSelectedActivity(value)} />

      <Button type="primary" style={{ cursor: 'pointer' }} onClick={getData} loading={loading}>
        Get data
      </Button>
      <div style={{ marginTop: 10 }}></div>
      <p>Default searching results for 24 hours period ( If hour block not selected )</p>
      <p>Default sorted via: Activity Frequency (All)</p>

      {calculations && (
        <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
          {data?.length > 0 && <p>Total results: {data?.length} </p>}
          <p>Grand Activity Frequency (Completed): {calculations.grandTotalCompletedActivities} </p>
          <p>Grand Activity Frequency (All): {calculations.grandTotalActivityTaken} </p>
        </div>
      )}
      <Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 300 }} />
    </Layout>
  )
}

export default MemberActivities
