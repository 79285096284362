import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Modal, Avatar, Space, Input, Button } from 'antd'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'
import classes from './styles/Member.module.css'

const pageOne = [
  { id: '1', label: 'Instagram', value: 23 },
  { id: '2', label: 'Tik-Tok', value: 54 },
  { id: '3', label: 'LinkedIn', value: 76 },
  { id: '4', label: 'Club Member', value: 67 },
  { id: '5', label: 'Friend', value: 77 },
  { id: '6', label: 'Google Search', value: 89 },
  { id: '7', label: 'Other', value: 95 },
]

const pageTwo = [
  { id: '1', label: 'Personal Development', value: 101 },
  { id: '2', label: 'Professional Development', value: 102 },
  { id: '3', label: 'People Development', value: 104 },
  { id: '4', label: 'Play/Experiential', value: 107 },
  { id: '5', label: 'Health & Fitness', value: 108 },
  { id: '6', label: 'Financial Health', value: 109 },
  { id: '7', label: 'Mental/Emotional/Spiritual Health', value: 103 },
  { id: '8', label: 'None of the above', value: 112 },
]

const pageThree = [
  { id: '1', label: 'I have success executing priorities', value: 201 },
  { id: '2', label: 'I have success building healthy habits', value: 202 },
  {
    id: '3',
    label: 'I can set, plan and lead projects and challenges from start to finish',
    value: 204,
  },
  {
    id: '4',
    label: 'I can set, plan and lead goals from start to finish',
    value: 207,
  },
]

const pageFour = [
  {
    id: '1',
    label: 'Little to no success leading myself and others',
    value: 301,
  },
  {
    id: '2',
    label: 'Noticeable success leading myself and others',
    value: 302,
  },
  { id: '3', label: 'Sustained success leading myself and others', value: 304 },
  { id: '4', label: 'Sustained success leading leaders', value: 307 },
  { id: '5', label: 'Sustained success leading change', value: 308 },
]

function Member() {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})
  const [userClass, setUserClass] = useState(``)
  const navigate = useNavigate()

  let { id } = useParams()
  const getAUser = async (values) => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/admin/getAUser?id=${id}`)
      // console.log(response.data)
      setUser(response.data.user)
      if (response.data.user.class) setUserClass(response.data.user.class)

      setLoading(false)
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      setLoading(false)
    }
  }

  const updateAUser = async () => {
    try {
      setLoading(true)

      const body = {
        ...(userClass ? { class: userClass } : {}),
      }

      const response = await axiosInstance.post(`/api/v1/admin/updateAUser?id=${id}`, body)
      // console.log(response.data)

      Modal.info({
        title: 'Success',
        content: (
          <div>
            <p>Your information has been udpated!</p>
          </div>
        ),
        onOk() {},
      })

      setLoading(false)
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      setLoading(false)
    }
  }

  const questionnaireItems = user?.questionnaireOne?.map((item, index) => {
    const { a: answer, q: question } = item // Destructuring for clarity
    const answerArray = []
    // console.log(answer)

    if (!answer || !question) {
      // Handle empty objects gracefully
      return null // Omit empty items from rendering
    }

    if (question == 'source') {
      answer?.forEach((answerItem) => {
        pageOne.forEach((pageItem) => {
          if (answerItem == pageItem.value) {
            answerArray.push(pageItem)
          }
        })
      })
    }

    if (question == 'investedOwn') {
      console.log(answer)
      answer?.forEach((answerItem) => {
        pageTwo.forEach((pageItem) => {
          if (answerItem == pageItem.value) {
            answerArray.push(pageItem)
          }
        })
      })
    }

    if (question == 'investedOthers') {
      console.log(answer)
      answer?.forEach((answerItem) => {
        pageThree.forEach((pageItem) => {
          if (answerItem == pageItem.value) {
            answerArray.push(pageItem)
          }
        })
      })
    }

    if (question == 'interestedArea') {
      console.log(answer)
      answer?.forEach((answerItem) => {
        pageFour.forEach((pageItem) => {
          if (answerItem == pageItem.value) {
            answerArray.push(pageItem)
          }
        })
      })
    }

    let formattedQuestion
    switch (question) {
      case 'source':
        formattedQuestion = 'User found this platform at the source of:'
        break
      case 'investedOwn':
        formattedQuestion = 'Years invested in their own health and fitness:'
        break
      case 'investedOthers':
        formattedQuestion = "Years invested in others' health and fitness:"
        break
      case 'interestedArea':
        formattedQuestion = 'Area of interest in health and fitness:'
        break
      default:
        formattedQuestion = question // Use original question for unknown keys
    }

    return (
      <div key={index} className={classes.questionnaireItem}>
        <span className={classes.question}>{formattedQuestion}</span>
        {answerArray.map((el) => {
          return <span className={classes.answer}>{el.label}</span>
        })}
      </div>
    )
  })

  useEffect(() => {
    getAUser()
  }, [])

  return (
    <Layout>
      <ArrowLeftOutlined
        style={{
          color: '#1677ff',
          fontSize: '1.5rem',
          marginBottom: 20,
          cursor: 'pointer',
        }}
        onClick={() => navigate('/members')}
      />
      <div>Member info</div>
      <br />
      <Avatar
        size={{
          xs: 24,
          sm: 32,
          md: 40,
          lg: 64,
          xl: 80,
          xxl: 100,
        }}
        src={
          (user.profilePicture && user.profilePicture[0].bucketResponse.mediaLink) ||
          `https://storage.googleapis.com/growdie/contents/manual-uploads/placeholder-people-compress-1.png`
        }
      />
      {user.profilePicture?.[0].bucketResponse?.size && <p>Image size: {(user.profilePicture?.[0].bucketResponse?.size / (1024 * 1024)).toFixed(3)} MB</p>}

      <div className={classes.containerOne}>
        <p className={classes.label}>Name</p>
        <p>{`${user.firstName} ${user.lastName}`}</p>
        <p className={classes.label}>Email</p>
        <p> {`${user.email}`}</p>

        <p className={classes.label}>Time zone GMT (Caution! It's live update while you type! ⚠️)</p>
        <p>Current one: {user.timeZoneGMT}</p>
        <Input
          style={{ width: 200 }}
          placeholder="Type like: +6 or -5"
          onChange={async (e) => {
            await axiosInstance.post(`/api/v1/admin/updateAUser?id=${id}`, { timeZoneGMT: e.target.value })
          }}
        />

        <p className={classes.label}>Email verified</p>
        <p>Auto</p>

        <p className={classes.label}>Signup type</p>
        <p>{user.signupType}</p>

        <p className={classes.label}>Joined At</p>
        <p>{user.joinedAt ? <>{user.joinedAt.map((el, idx) => `|| ${el.name} ||`)}</> : 'Not found'}</p>

        <p className={classes.label}>User type</p>
        <p>{user.type}</p>

        <p className={classes.label}>Digital access</p>
        <p>{user.digitalAccess ? 'Yes' : 'No'}</p>

        <p className={classes.label}>Created at</p>
        <p>{new Date(user.createdAt).toLocaleString()}</p>

        <p className={classes.label}>Address</p>
        <p>{user.address}</p>

        <p className={classes.label}>State</p>
        <p>{user.questionnaireTwo?.state}</p>

        <p className={classes.label}>Gender</p>
        <p>{user.gender}</p>

        <p className={classes.label}>Age</p>
        <p>{user.age}</p>

        <p className={classes.label}>Biography</p>
        <p>{user.biography}</p>

        <p className={classes.label}>Phone</p>
        <p>{user.phone}</p>

        <p className={classes.label}>Date of birth</p>
        <p>{user.dateOfBirth && new Date(user.dateOfBirth).toLocaleDateString()}</p>

        <p className={classes.label}>Questionnaire set one</p>
        {questionnaireItems}

        <p className={classes.label}>User class</p>
        <Space.Compact
          style={{
            width: '50%',
          }}
        >
          <Input
            placeholder="Write class name and submit"
            value={userClass}
            onChange={(e) => {
              setUserClass(e.target.value)
            }}
          />
          <Button type="primary" onClick={updateAUser}>
            Submit
          </Button>
        </Space.Compact>
      </div>
    </Layout>
  )
}

export default Member
