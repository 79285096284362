import React, { useEffect, useState } from 'react'
import { DatePicker, Select, Button, Table } from 'antd'
import moment from 'moment'

import Layout from '../../components/layout/Layout'
import axiosInstance from '../../utils/axiosInstance'

const { RangePicker, Avatar } = DatePicker

const columns = [
  {
    title: 'First Name',
    key: 'firstName',
    render: (data) => <div>{data.firstName}</div>,
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    title: 'Last Name',
    key: 'lastName',
    render: (data) => <div>{data.lastName}</div>,
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
  },
  {
    title: 'Start date',
    key: 'startDate',
    render: (data) => <div>{data.startDate && moment(new Date(data.startDate)).format('MMMM DD, YYYY h:mm:ss a')}</div>,
  },
  {
    title: 'Last time in app',
    key: 'lastActive',
    render: (data) => <div>{data.lastActive && moment(new Date(data.lastActive.updatedAt)).format('MMMM DD, YYYY h:mm:ss a')}</div>,
  },
  {
    title: 'Total likes (given & received)',
    key: 'totalLikes',
    render: (data) => <div>{data.totalLikes}</div>,
    sorter: (a, b) => a.totalLikes - b.totalLikes,
  },
  {
    title: 'Total comments (given & received)',
    key: 'totalComments',
    render: (data) => <div>{data.totalComments}</div>,
    sorter: (a, b) => a.totalComments - b.totalComments,
  },
  {
    title: 'Total messages (given & received)',
    key: 'totalPrivateMessages',
    render: (data) => <div>{data.totalPrivateMessages}</div>,
    sorter: (a, b) => a.totalPrivateMessages - b.totalPrivateMessages,
  },
  {
    title: 'Total tags',
    key: 'totalTags',
    render: (data) => <div>{data.totalTags}</div>,
    sorter: (a, b) => a.totalTags - b.totalTags,
  },
]

function EngagementPage() {
  const [loading, setLoading] = useState(false)
  const [organizations, setOrganizations] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')
  const [data, setData] = useState(null)
  const [calculations, setCalculations] = useState(null)

  const getMyInfo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/admin/me`)

      // Check the admin organizations
      if (response?.data?.user?.organizations && response?.data?.user?.organizations.length > 0) {
        const org = response.data.user.organizations
        // console.log(org)

        // store to render at JSX
        org.forEach((element, idx) => {
          if (idx == 0) setSelectedOrg(element._id) // auto selecting the first organization to fetch some results
          setOrganizations((prevElements) => [
            ...prevElements,
            {
              value: element._id,
              label: element.name,
            },
          ])
        })
      }

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getData = async () => {
    try {
      setLoading(true)

      const payload = {
        summaryOfClub: selectedOrg,
      }

      console.log(payload)

      const response = await axiosInstance.post(`/api/v1/metrics/engagementPage`, payload)

      //   console.log(response.data)
      setData(response.data?.results)
      setCalculations(response.data?.calculations)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyInfo()
  }, [])

  if (organizations.length == 0) return null

  return (
    <Layout>
      <h1>Engagement Page</h1>
      <Select
        placeholder="Please select club or business"
        style={{ width: 250 }}
        defaultValue={organizations[0].value}
        onChange={(value) => setSelectedOrg(value)}
        options={organizations}
      />

      <Button type="primary" style={{ cursor: 'pointer' }} onClick={getData} loading={loading}>
        Get data
      </Button>
      <div style={{ marginTop: 10 }}></div>
      {calculations && (
        <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
          {data?.length > 0 && <p>Total results: {data?.length} </p>}
          <p>Total likes (given & received): {calculations.grandTotalLikes} </p>
          <p>Total comments (given & received): {calculations.grandTotalComments} </p>
          <p>Total messages (given & received) {calculations.grandTotalPrivateMessages} </p>
          <p>Total tags {calculations.grandTotalTags} </p>
        </div>
      )}
      <Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 300 }} />
    </Layout>
  )
}

export default EngagementPage
