import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'

import Auth from './auth/Auth'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Referrals from '../pages/Referrals'
import Profile from '../pages/Profile'
import Logs from '../pages/Logs'
import Members from '../pages/Members'
import Member from '../pages/Member'
import FocusAreas from '../pages/FocusAreas'
import FocusArea from '../pages/FocusArea'
import EditFocusArea from '../pages/EditFocusArea'
import ActivitySuggestions from '../pages/ActivitySuggestions'
import ClubEvents from '../pages/ClubEvents'
import ReportedFeedPosts from '../pages/ReportedFeedPosts'
import AnEventDetails from '../pages/AnEventDetails'
import ReportedCelebrationPosts from '../pages/ReportedCelebrationPosts'
import AnCelebrationDetails from '../pages/AnCelebrationDetails'
import Metrics from '../pages/Metrics'
import SummaryPage from '../pages/metricsPages/SummaryPage'
import ActivitiesPage from '../pages/metricsPages/ActivitiesPage'
import AppInputs from '../pages/AppInputs'
import MemberActivities from '../pages/metricsPages/MemberActivities'
import ProveIt from '../pages/metricsPages/ProveIt'
import FocusAreasPage from '../pages/metricsPages/FocusAreasPage'
import EngagementPage from '../pages/metricsPages/EngagementPage'

function R() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Authenticated route below */}
        <Route path="/" element={<Auth children={<Outlet />} />}>
          <Route path="" element={<Home />}></Route>
          <Route path="home" element={<Home />}></Route>
          <Route path="referrals" element={<Referrals />}></Route>
          <Route path="profile" element={<Profile />}></Route>
          <Route path="logs" element={<Logs />}></Route>
          <Route path="activitySuggestions" element={<ActivitySuggestions />}></Route>
          <Route path="members" element={<Members />}></Route>
          <Route path="member/:id" element={<Member />}></Route>
          <Route path="focusAreas" element={<FocusAreas />}></Route>
          <Route path="FocusArea/:id" element={<FocusArea />}></Route>
          <Route path="editFocusArea/:parentId/:childId" element={<EditFocusArea />}></Route>
          <Route path="clubEvents" element={<ClubEvents />}></Route>
          <Route path="reportedFeedPosts" element={<ReportedFeedPosts />}></Route>
          <Route path="anEventDetails/:eventId" element={<AnEventDetails />}></Route>
          <Route path="reportedCelebrationPosts" element={<ReportedCelebrationPosts />}></Route>
          <Route path="anCelebrationDetails/:celebrationId" element={<AnCelebrationDetails />}></Route>
          <Route path="metrics" element={<Metrics />}></Route>
          <Route path="metrics/SummaryPage" element={<SummaryPage />}></Route>
          <Route path="metrics/activitiesPage" element={<ActivitiesPage />}></Route>
          <Route path="metrics/memberActivities" element={<MemberActivities />}></Route>
          <Route path="metrics/proveIt" element={<ProveIt />}></Route>
          <Route path="metrics/focusAreasPage" element={<FocusAreasPage />}></Route>
          <Route path="metrics/engagementPage" element={<EngagementPage />}></Route>
          <Route path="appInputs" element={<AppInputs />}></Route>
        </Route>

        {/* Public routes below */}
        <Route exact path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  )
}

export default R
