import React, { useState, useRef, useEffect } from 'react'
import { Table, Avatar, Space, Drawer, Button, Select, Spin, Tag } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ClearOutlined } from '@ant-design/icons'
import moment from 'moment'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'
import classes from './styles/Members.module.css'

function Members() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    applyFilters()
  }, [filters, users])

  // Result pattern:
  // result = main
  // result = filter1 from result
  // result = filter2 from the previous result above
  // now result will show combination of two result based on order
  const applyFilters = () => {
    let result = users
    for (const key in filters) {
      if (filters[key] !== 'all') {
        result = result.filter((user) => user[key] === filters[key])
      }
    }
    setFilteredUsers(result)
  }

  // for setting all the filters at the global state
  const masterFilter = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }))
  }

  const showDrawer = () => {
    setFilterDrawerOpen(true)
  }
  const onClose = () => {
    setFilterDrawerOpen(false)
  }

  const columns = [
    // {
    //   title: '#',
    //   key: 'no.',
    //   render: (text, record, rowIndex) => rowIndex + 1,
    // },
    {
      title: '',
      dataIndex: 'profilePicture',
      key: 'profilePicture',
      render: (profilePicture) => (
        <Avatar
          src={(profilePicture && profilePicture[0].bucketResponse.mediaLink) || `https://storage.googleapis.com/growdie/contents/manual-uploads/placeholder-people-compress-1.png`}
        />
      ),
      width: 50,
    },
    {
      title: 'Name',
      key: 'name',
      // width: 90,
      render: (data) => (
        <div>
          {data.firstName} {data.lastName}
        </div>
      ),
    },
    {
      title: 'Payment',
      key: 'payment',
      render: (data) => {
        // These are the users, for them we forcefully bypassed payemnt
        if (data.myUser)
          return (
            <div>
              <Tag color="#87d068">My User</Tag>
            </div>
          )

        // They are not subscribed yet (Maybe will subscribe in future)
        if (!data.appleOriginalTransactionId) {
          return (
            <div>
              <Tag color="purple">Free version</Tag>
            </div>
          )
        }

        // console.log(data.appleSubscriptionStatusCache?.decodedSignedTransactionInfo?.expiresDate)
        // console.log(Date.now())
        if (Date.now() > data.appleSubscriptionStatusCache?.decodedSignedTransactionInfo?.expiresDate) {
          // console.log('Subscription Expired')
          return (
            <div>
              <Tag color="#f50">Subscription Expired</Tag>
              <p style={{ fontSize: 10 }}>Expired: {moment(data.appleSubscriptionStatusCache?.decodedSignedTransactionInfo?.expiresDate).format('DD MMMM YY h:mm a')}</p>
            </div>
          )
        } else {
          // console.log('Subscription valid')
          if (!data.appleSubscriptionStatusCache) return '' // That means info not ready yet to make it visible, even if the "appleOriginalTransactionId" is found. ( need to run the API who can prefil 'appleSubscriptionStatusCache')
          return (
            <div>
              <Tag color="#108ee9">Subscription valid</Tag>
              <p style={{ fontSize: 10 }}>Expires: {moment(data.appleSubscriptionStatusCache?.decodedSignedTransactionInfo?.expiresDate).format('DD MMMM YY h:mm a')}</p>
              <p style={{ fontSize: 10 }}>{data.appleSubscriptionStatusCache?.decodedSignedTransactionInfo?.environment}</p>
              <p style={{ fontSize: 10 }}>{data.appleSubscriptionStatusCache?.decodedSignedTransactionInfo?.offerDiscountType}</p>
            </div>
          )
        }
      },
    },
    // {
    //   title: 'GMT (Temporary)',
    //   key: 'timeZoneGMT',
    //   render: (record) => <div>{record.timeZoneGMT}</div>,
    // },
    {
      title: 'Versions',
      key: 'versions',
      render: (record) => (
        <div>
          {record.deviceToken?.map((el, idx) => {
            return (
              <span>
                {el.moreInfo?.installedAppVersion}__{moment(el.lastActive).fromNow()} ￮
              </span>
            )
          })}
        </div>
      ),
    },
    {
      title: 'Signup Type',
      dataIndex: 'signupType',
      key: 'signupType',
      render: (signupType) => <p style={{ fontSize: 9 }}>{signupType}</p>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email) => <p style={{ fontSize: 9 }}>{email}</p>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <p style={{ fontSize: 9 }}>{moment(createdAt).format('DD MMMM YY h:mm a')}</p>,
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',

      render: (_, record) => (
        <Space size="middle">
          <a href={`/member/${record._id}`} target="_blank" rel="noopener noreferrer" style={{ color: '#1677ff', cursor: 'pointer' }}>
            Edit
          </a>
        </Space>
      ),
    },
  ]

  const getAllUsers = async (values) => {
    console.log('Success:', values)

    try {
      setLoading(true)

      const response = await axiosInstance.get('/api/v1/admin/getAllUsers')
      console.log(response.data)
      setUsers(response.data.users)
      setFilteredUsers(response.data.users)

      setLoading(false)
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      setLoading(false)
    }
  }

  // ! Old filter method ( but it can only filter only one filter at a time )
  const signupTypeChange = (value) => {
    // under 1000 users do frontend filtering
    console.log(value)
    const res = users.filter((el, idx) => {
      if (el.signupType == value) {
        return el
      } else {
        return false
      }
    })
    setFilteredUsers(res)
    if (value == 'all') {
      setFilteredUsers(users)
    }
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  return (
    <Layout>
      <h1>Members ({filteredUsers.length})</h1>
      {loading && <Spin size="large" />}
      <br />
      <Button onClick={showDrawer}>Add filter</Button>

      {Object.keys(filters).length != 0 && (
        <Button onClick={() => window.location.reload()} icon={<ClearOutlined />} type="text" danger>
          Clear filter
        </Button>
      )}

      <Drawer title="Filters" onClose={onClose} open={filterDrawerOpen}>
        <div className={classes.child}>
          <p>Signup Type</p>
          <Select
            className={classes.selectTag}
            onChange={(v) => masterFilter('signupType', v)}
            options={[
              {
                value: 'all',
                label: 'All type',
              },
              {
                value: 'digital',
                label: 'Digital Community',
              },
              {
                value: 'growdieCode',
                label: 'Growdie Code',
              },
            ]}
          />
        </div>
        <div className={classes.child}>
          <p>User Type</p>
          <Select
            className={classes.selectTag}
            onChange={(v) => masterFilter('type', v)}
            options={[
              {
                value: 'all',
                label: 'All type of users',
              },
              {
                value: 'regular-user',
                label: 'Regular User',
              },
              {
                value: 'super-admin',
                label: 'Super Admin',
              },
              {
                value: 'regular-admin',
                label: 'Regular Admin',
              },
            ]}
          />
        </div>
      </Drawer>
      <Table
        scroll={{
          x: 1300,
        }}
        dataSource={filteredUsers}
        columns={columns}
        rowKey="_id"
        pagination={false}
      />
    </Layout>
  )
}

export default Members

const styles = {
  filterContainer: {
    background: '#f2f2f2',
  },
}
