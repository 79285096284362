import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DatePicker, Select, Button, Table } from 'antd'

import Layout from '../../components/layout/Layout'
import axiosInstance from '../../utils/axiosInstance'

const { RangePicker, Avatar } = DatePicker

const timeZones = [
  {
    value: '-5',
    label: '-5',
  },
  {
    value: '-6',
    label: '-6',
  },
  {
    value: '+5',
    label: '+5',
  },
  {
    value: '+6',
    label: '+6',
  },
]

const timeBlocks = [
  {
    value: '12:01am-4am',
    label: '12:01am-4am',
  },
  {
    value: '4:01am-8am',
    label: '4:01am-8am',
  },
  {
    value: '8:01am-12pm',
    label: '8:01am-12pm',
  },
  {
    value: '12:01pm-4pm',
    label: '12:01pm-4pm',
  },
  {
    value: '4:01pm-8pm',
    label: '4:01pm-8pm',
  },
  {
    value: '8:01pm-12am',
    label: '8:01pm-12am',
  },
]

const columns = [
  {
    title: 'Subtitle',
    dataIndex: 'subTitle',
    key: 'subTitle',
    sorter: (a, b) => a.subTitle.localeCompare(b.subTitle),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    sorter: (a, b) => a.category.localeCompare(b.category),
  },
  {
    title: 'Focus Area',
    dataIndex: 'parent',
    key: 'parent',
    sorter: (a, b) => a.parent.localeCompare(b.parent),
  },
  {
    title: 'Total Points',
    dataIndex: 'totalCompletedPoints',
    key: 'totalCompletedPoints',
    sorter: (a, b) => a.totalCompletedPoints - b.totalCompletedPoints,
  },
  {
    title: 'Activity Frequency (Completed)',
    dataIndex: 'totalCompletedEvents',
    key: 'totalCompletedEvents',
    sorter: (a, b) => a.totalCompletedEvents - b.totalCompletedEvents,
  },
  {
    title: 'Activity Frequency (All)',
    dataIndex: 'totalAddedToPeoplesCalendar',
    key: 'totalAddedToPeoplesCalendar',
    sorter: (a, b) => a.totalAddedToPeoplesCalendar - b.totalAddedToPeoplesCalendar,
  },
  {
    title: 'Average Rating',
    dataIndex: 'averageRating',
    key: 'averageRating',
    render: (data) => <div>{data ? <span>{data.toFixed(2)}% +</span> : <span>{0}</span>}</div>,
    sorter: (a, b) => a.averageRating - b.averageRating,
  },
  {
    title: 'Completion %',
    dataIndex: 'percentageCompleted',
    key: 'percentageCompleted',
    render: (data) => <div>{data ? <span>{data.toFixed(2)}% +</span> : <span>{0}</span>}</div>,
    sorter: (a, b) => a.percentageCompleted - b.percentageCompleted,
  },
]

function ActivitiesPage() {
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [organizations, setOrganizations] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')
  const [timeZone, setTimeZone] = useState(timeZones[0].value)
  const [hourBlock, setHourBlock] = useState('')
  const [data, setData] = useState(null)

  const getMyInfo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/admin/me`)

      // Check the admin organizations
      if (response?.data?.user?.organizations && response?.data?.user?.organizations.length > 0) {
        const org = response.data.user.organizations
        // console.log(org)

        // store to render at JSX
        org.forEach((element, idx) => {
          if (idx == 0) setSelectedOrg(element._id) // auto selecting the first organization to fetch some results
          setOrganizations((prevElements) => [
            ...prevElements,
            {
              value: element._id,
              label: element.name,
            },
          ])
        })
      }

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getData = async () => {
    try {
      if (!startDate || !endDate) {
        console.log(startDate, endDate)
        return
      }

      setLoading(true)

      const payload = {
        timeZone,
        startDate,
        endDate,
        summaryOfClub: selectedOrg,
        ...(hourBlock ? { hourBlock } : {}),
      }

      console.log(payload)

      const response = await axiosInstance.post(`/api/v1/metrics/activitiesPage`, payload)

      //   console.log(response.data)
      setData(response.data?.results)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleRangePicker = (value) => {
    // console.log('Selected Time: ', value)
    value?.map((el, idx) => {
      // console.log(moment(new Date(el)).format('MMMM DD, YYYY h:mm:ss a')) // Readable format
      // console.log(new Date(el).getTime()) // Epoch timestamp in miliseconds
      let start = 0
      let end = 0
      if (idx == 0) {
        start = new Date(el).getTime()
        setStartDate(start)
      } else if (idx == 1) {
        end = new Date(el).getTime()
        setEndDate(end)
      }
      console.log(start)
      console.log(end)
    })
  }

  useEffect(() => {
    getMyInfo()
  }, [])

  if (organizations.length == 0) return null

  return (
    <Layout>
      <h1>Activities Page</h1>
      <RangePicker onChange={handleRangePicker} />
      <Select
        placeholder="Please select club or business"
        style={{ width: 250 }}
        defaultValue={organizations[0].value}
        onChange={(value) => setSelectedOrg(value)}
        options={organizations}
      />
      <Select placeholder="Please select timezone" style={{ width: 100 }} defaultValue={timeZones[0].value} onChange={(value) => setTimeZone(value)} options={timeZones} />
      <Select placeholder="Select a hour block" style={{ width: 200 }} onChange={(value) => setHourBlock(value)} options={timeBlocks} allowClear />
      <Button type="primary" style={{ cursor: 'pointer' }} onClick={getData} loading={loading}>
        Get data
      </Button>
      <div style={{ marginTop: 10 }}></div>
      <p>Default searching results for 24 hours period ( If hour block not selected )</p>
      <p>Default sorted via: Activity Frequency (All)</p>
      <Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 300 }} />
    </Layout>
  )
}

export default ActivitiesPage
