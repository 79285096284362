import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Modal, Avatar, Space, Input, InputNumber, Button, Popconfirm, Table, Select, Spin } from 'antd'
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function FocusArea() {
  const navigate = useNavigate()
  let { id } = useParams()

  const auth = useSelector((state) => state.auth)

  const [loading, setLoading] = useState(false)
  const [focusItems, setFocusItems] = useState([])
  const [focusArea, setOrgFocusArea] = useState({})
  const [createFocusAreaModal, setCreateFocusAreaModal] = useState(false)
  const [title, setTitle] = useState('')
  const [form, setForm] = useState({
    subTitle: '',
    points: '',
    quickDescription: '',
    category: '',
    level: '', // it will be string after selecting from select tag
  })

  const columns = [
    // {
    //   title: 'Profile Picture',
    //   dataIndex: 'icon',
    //   key: 'icon',
    //   render: (icon) => (
    //     <Avatar src={icon && icon?.bucketResponse?.mediaLink} />
    //   ),
    // },
    {
      title: 'Sub category name',
      dataIndex: 'subTitle',
      key: 'subTitle',
      sorter: (a, b) => a.subTitle.localeCompare(b.subTitle),
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      sorter: (a, b) => a.points - b.points,
    },
    // {
    //   title: 'Extra Informations',
    //   dataIndex: 'extraInfo',
    //   key: 'extraInfo',
    // },
    {
      title: 'Quick Desccription',
      dataIndex: 'quickDescription',
      key: 'quickDescription',
    },
    // Below one only for dev mode to get the item _id quickly
    // {
    //   title: '_id',
    //   dataIndex: '_id',
    //   key: '_id',
    // },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      sorter: (a, b) => a.level - b.level,
      render: (data) => <div>{data == 1 ? <span>Everyone</span> : <span>Admin</span>}</div>,
    },
    {
      title: 'Mandatory Priorities',
      key: 'habitsPriorities.subTitle343242',
      render: (_, record) => (
        <div style={{ maxHeight: 200, overflow: 'auto', border: '1px solid white', width: 100 }}>
          {record.habitsPriorities?.map((el, ky) => {
            return (
              <p key={ky} style={{ fontSize: 10 }}>
                ⚫{el.subTitle}
              </p>
            )
          })}
        </div>
      ),
    },
    {
      title: 'Mandatory Habits',
      key: 'mandatoryHabits.subTitle343242',
      render: (_, record) => (
        <div style={{ maxHeight: 200, overflow: 'auto', border: '1px solid white', width: 100 }}>
          {record.mandatoryHabits?.map((el, ky) => {
            return (
              <p key={ky} style={{ fontSize: 10 }}>
                ⚫{el.subTitle}
              </p>
            )
          })}
        </div>
      ),
    },
    {
      title: 'Optional Habits',
      key: 'optionalHabits.subTitle343242',
      render: (_, record) => (
        <div style={{ maxHeight: 200, overflow: 'auto', border: '1px solid white', width: 100 }}>
          {record.optionalHabits?.map((el, ky) => {
            return (
              <p key={ky} style={{ fontSize: 10 }}>
                ⚫{el.subTitle}
              </p>
            )
          })}
        </div>
      ),
    },
    {
      title: 'Optional Priorities',
      key: 'optionalPriorities.subTitle343242',
      render: (_, record) => (
        <div style={{ maxHeight: 200, overflow: 'auto', border: '1px solid white', width: 100 }}>
          {record.optionalPriorities?.map((el, ky) => {
            return (
              <p key={ky} style={{ fontSize: 10 }}>
                ⚫{el.subTitle}
              </p>
            )
          })}
        </div>
      ),
    },
    {
      title: 'Optional Challenges',
      key: 'optionalChallenges.subTitle343242',
      render: (_, record) => (
        <div style={{ maxHeight: 200, overflow: 'auto', border: '1px solid white', width: 100 }}>
          {record.optionalChallenges?.map((el, ky) => {
            return (
              <p key={ky} style={{ fontSize: 10 }}>
                ⚫ {el.subTitle}
              </p>
            )
          })}
        </div>
      ),
    },
    {
      title: 'Mandatory Challenges',
      key: 'mandatoryChallenges.subTitle373242',
      render: (_, record) => (
        <div style={{ maxHeight: 200, overflow: 'auto', border: '1px solid white', width: 100 }}>
          {record.mandatoryChallenges?.map((el, ky) => {
            return (
              <p key={ky} style={{ fontSize: 10 }}>
                ⚫ {el.subTitle}
              </p>
            )
          })}
        </div>
      ),
    },
    {
      title: 'Disabled',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (dis) => (dis ? 'TRUE' : 'FALSE'),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      sorter: (a, b) => a.disabled - b.disabled,
      render: (_, record) => (
        <>
          <Button type="ghost" style={{ color: '#1677ff', cursor: 'pointer' }} onClick={() => navigate(`/editFocusArea/${id}/${record._id}`)}>
            Edit
          </Button>
          {record.disabled ? (
            <Button type="ghost" style={{ color: '#1677ff', cursor: 'pointer' }} onClick={() => handlePublish(record._id)}>
              Publish
            </Button>
          ) : (
            <Popconfirm
              title="Are you sure to delete this?"
              description="This will no longer visible to users for add their tasks. But whoever already added this, they can track their progress."
              onConfirm={() => onDelete(`${record._id}`)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button type="ghost" style={{ color: 'red', cursor: 'pointer' }}>
                Disable
              </Button>
            </Popconfirm>
          )}
        </>
      ),
    },
  ]

  const onDelete = async (subCategoryId) => {
    const response = await axiosInstance.delete(`/api/v1/focus/deleteSubFocus?subFocusId=${subCategoryId}`)
    alert('Success')
    window.location.reload()
  }
  const handlePublish = async (subCategoryId) => {
    const response = await axiosInstance.put(`/api/v1/focus/editSubFocus?subFocusId=${subCategoryId}`, { disabled: 'false' })
    alert('Success')
    window.location.reload()
  }

  const showModal = () => {
    setCreateFocusAreaModal(true)
  }

  const getFocusAreaInfo = async (v) => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/focus/getFocusAreaById?focusId=${id}`)

      setOrgFocusArea(response.data.focusArea)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getFocusItems = async () => {
    try {
      if (auth.user.organizations?.length) {
        setLoading(true)

        const response = await axiosInstance.get(`/api/v1/focus/focusItems?organization=${auth.user.organizations[0]}&parent=${id}&sendDisabled=true`)

        // console.log(response.data)
        setFocusItems(response.data?.focusItems)

        setLoading(false)
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const createSubFocus = async () => {
    try {
      // console.log(form)

      // Check if any field is empty
      const isEmptyField = Object.values(form).some((value) => {
        if (typeof u === 'string') {
          console.log('myVariable is a string')
          if (value.trim() === '') return true
        } else {
          console.log('myVariable is not a string')
          if (value === '') return true
        }
      })
      if (isEmptyField) {
        alert('All fields are required and cannot be empty.')
        return
      }

      setLoading(true)

      const response = await axiosInstance.post(`/api/v1/focus/createSubFocus?focusId=${id}`, form)

      console.log(response.data)
      window.location.reload()

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const onChange = (obj) => {
    console.log(obj)
    setForm((prev) => ({ ...prev, ...obj }))
  }

  useEffect(() => {
    getFocusItems()
    getFocusAreaInfo()
  }, [])

  return (
    <Layout>
      <Modal
        title="Add sub category"
        open={createFocusAreaModal}
        footer={null}
        onCancel={() => {
          setCreateFocusAreaModal(false)
        }}
      >
        <Input placeholder="Sub title" onChange={(e) => onChange({ subTitle: e.target.value })} style={{ marginTop: 10 }} />
        <InputNumber
          placeholder="Points (Max:100000,  Min:1)"
          min={1}
          max={100000}
          onChange={(e) => onChange({ points: e })} // sending integer
          style={{ marginTop: 10, width: '100%' }}
        />
        {/* <Input
          placeholder="Extra info"
          onChange={(e) => onChange({ extraInfo: e.target.value })}
          style={{ marginTop: 10 }}
        /> */}
        <Input placeholder="Quick description" onChange={(e) => onChange({ quickDescription: e.target.value })} style={{ marginTop: 10 }} />
        <Select
          placeholder="Choose category"
          style={{
            width: `100%`,
            marginTop: 10,
          }}
          onChange={(value) => onChange({ category: value })}
          options={[
            {
              value: 'priorities',
              label: 'Priorities',
            },
            {
              value: 'habits',
              label: 'Habits',
            },
            {
              value: 'challenges',
              label: 'Challenges',
            },
            {
              value: 'goals',
              label: 'Goals',
            },
          ]}
        />
        {form.category == 'habits' && (
          <>
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Mandatory priorities"
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ habitsPriorities: value })}
              options={focusItems
                .filter((el) => el.category == 'priorities')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Optional Priorities"
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ optionalPriorities: value })}
              options={focusItems
                .filter((el) => el.category == 'priorities')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
          </>
        )}
        {form.category == 'challenges' && (
          <>
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Choose mandatory habits  "
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ mandatoryHabits: value })}
              options={focusItems
                .filter((el) => el.category == 'habits')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Choose optional habits  "
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ optionalHabits: value })}
              options={focusItems
                .filter((el) => el.category == 'habits')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Mandatory priorities"
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ habitsPriorities: value })}
              options={focusItems
                .filter((el) => el.category == 'priorities')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Optional priorities "
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ optionalPriorities: value })}
              options={focusItems
                .filter((el) => el.category == 'priorities')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
          </>
        )}
        {form.category == 'goals' && (
          <>
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Mandatory challenges"
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => {
                console.log(value)
                // alert('hi')
                // onChange({ mandatoryChallenges: value })
              }}
              options={focusItems
                .filter((el) => el.category == 'challenges')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Optional challenges"
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ optionalChallenges: value })}
              options={focusItems
                .filter((el) => el.category == 'challenges')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Mandatory habits"
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ mandatoryHabits: value })}
              options={focusItems
                .filter((el) => el.category == 'habits')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Optional habits"
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ optionalHabits: value })}
              options={focusItems
                .filter((el) => el.category == 'habits')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Mandatory priorities"
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ habitsPriorities: value })}
              options={focusItems
                .filter((el) => el.category == 'priorities')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
            <Select
              mode="multiple"
              optionFilterProp="label"
              placeholder="Optional priorities"
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ optionalPriorities: value })}
              options={focusItems
                .filter((el) => el.category == 'priorities')
                .map((el) => {
                  return {
                    label: el.subTitle,
                    value: el._id,
                  }
                })}
            />
          </>
        )}
        <br />
        <Select
          placeholder="Choose level"
          style={{
            width: `100%`,
            marginTop: 10,
          }}
          onChange={(value) => onChange({ level: value })}
          options={[
            {
              value: 1,
              label: 'Everyone',
            },
            {
              value: 2,
              label: 'Admin',
            },
          ]}
        />
        <Button type="primary" onClick={createSubFocus} loading={loading} style={{ marginTop: 10 }}>
          Save
        </Button>
        <br />
        <br />
      </Modal>
      <ArrowLeftOutlined
        style={{
          color: '#1677ff',
          fontSize: '1.5rem',
          marginBottom: 20,
          cursor: 'pointer',
        }}
        onClick={() => navigate('/focusAreas')}
      />
      <div>
        <Button type="primary" onClick={showModal} loading={loading}>
          Create sub category
        </Button>
      </div>
      {loading && <Spin size="large" />}
      <br />
      <p style={{ fontSize: 28 }}>
        {focusArea?.title} (Total: {focusItems.length})
      </p>
      <Table
        scroll={{
          x: 1300,
        }}
        dataSource={focusItems}
        columns={columns}
        rowKey="_id"
        pagination={false}
      />
    </Layout>
  )
}

export default FocusArea
