import React, { useState, useRef, useEffect } from 'react'
import { Table, Spin, Avatar, Button, Input, Switch } from 'antd'
import { useNavigate, useLocation } from 'react-router'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function AppInputs() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [version, setVersion] = useState('')
  const [desc, setDesc] = useState('')

  const getData = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/appInputs/getLatestAppStoreVersion`)

      console.log(response.data.currentAppstoreVersion)
      setData(response.data.currentAppstoreVersion)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const setAppStoreVersion = async () => {
    try {
      setLoading(true)

      if (!version || !desc) {
        return
      }

      const payload = {
        currentAppstoreVersion: version,
        description: desc,
      }

      const response = await axiosInstance.post(`/api/v1/appInputs/setAppStoreVersion`, payload)

      window.location.reload()

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const onChange = async (checked, id) => {
    const response = await axiosInstance.put(`/api/v1/appInputs/updateAppStoreVersionDocument?id=${id}`, { disablePopup: checked })
    console.log('Success')
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Layout>
      <div style={{ padding: '20px' }}>
        <h2>App Inputs</h2>
        {loading && <Spin />}

        {data && (
          <>
            <p>Latest info for appstore version:</p>
            <p>{data.currentAppstoreVersion}</p>
            <p>{data.description}</p>
            <Switch onChange={(checked) => onChange(checked, data._id)} defaultValue={data.disablePopup} checkedChildren="Disabled" unCheckedChildren="Enabled" />{' '}
            <span>Turn on/off the alert for users (Useful to turn off while app is in review )</span>
          </>
        )}

        <hr />
        <hr />
        <Input
          style={{ width: 200 }}
          placeholder="Type like: 1.0.1"
          onChange={async (e) => {
            setVersion(e.target.value)
          }}
        />
        <br />
        <Input.TextArea
          rows={4}
          style={{ width: 200, marginTop: 10 }}
          placeholder="Description"
          onChange={async (e) => {
            setDesc(e.target.value)
          }}
        />
        <br />
        <Button type="primary" style={{ cursor: 'pointer', marginTop: 10 }} onClick={setAppStoreVersion}>
          Save
        </Button>
        <hr />
      </div>
    </Layout>
  )
}

export default AppInputs
