import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Modal, Avatar, Space, Input, Button, Select, Upload, message, Typography, Table, Popconfirm, Spin, InputNumber, DatePicker } from 'antd'
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

import axios from 'axios'
import dayjs from 'dayjs'
const { Text, Paragraph, Link } = Typography

function ClubEvents() {
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)

  const [loading, setLoading] = useState(false)
  const [organizations, setOrganizations] = useState([])
  const [focusAreas, setOrgFocusAreas] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')
  const [createFocusAreaModal, setCreateFocusAreaModal] = useState(false)
  const [focusArea, setFocusArea] = useState('')
  const [form, setForm] = useState({
    subTitle: '',
    quickDescription: '',
    points: 0,
    link: '',
    dateTime: '',
  })
  const [data, setData] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [editingId, setEditingId] = useState(null)

  const onOk = (value) => {
    onChange({ dateTime: new Date(value) }) // store it with the form
  }

  // Text input field
  const onChange = (obj) => {
    // console.log(obj)
    setForm((prev) => ({ ...prev, ...obj }))
  }

  const columns = [
    // {
    //   title: 'Icon',
    //   dataIndex: 'focusArea',
    //   key: 'icon',
    //   render: (focusArea) => <Avatar src={focusArea && focusArea?.icon} />,
    // },
    {
      title: 'Title',
      dataIndex: 'subTitle',
      key: 'subTitle',
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
    },
    {
      title: 'Description',
      dataIndex: 'quickDescription',
      key: 'quickDescription',
      render: (quickDescription) => <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>{quickDescription} </Paragraph>,
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      render: (link) => (
        <Link href={link} target="_blank">
          {link}
        </Link>
      ),
    },
    {
      title: 'Focus Area',
      dataIndex: 'parent',
      key: 'parent',
      render: (data) => data[0]?.fullForm,
    },
    {
      title: 'Event At',
      dataIndex: 'dateTime',
      key: 'dateTime',
      render: (dateTime) => new Date(dateTime).toLocaleString(),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: 'Disabled',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (dis) => (dis ? 'TRUE' : 'FALSE'),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Space size="middle">
          {!record.disabled && (
            <Popconfirm
              title="Are you sure to disable this?"
              description="This will no longer visible to users for add their tasks. But whoever already added this, they can track their progress."
              onConfirm={() => {
                onDelete(record._id)
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <span style={{ color: 'red', cursor: 'pointer' }}>Disable</span>
            </Popconfirm>
          )}

          <span
            style={{ color: '#1677ff', cursor: 'pointer' }}
            onClick={() => {
              showModal()
              setForm({
                subTitle: record.subTitle,
                quickDescription: record.quickDescription,
                points: record.points,
                link: record.link,
                dateTime: record.dateTime,
              })
              setFocusArea(record.parent[0]?._id)
              setIsUpdating(true)
              setEditingId(record._id)
            }}
          >
            Edit
          </span>
        </Space>
      ),
    },
  ]

  const onDelete = async (subCategoryId) => {
    const response = await axiosInstance.delete(`/api/v1/focus/deleteSubFocus?subFocusId=${subCategoryId}`)
    alert('Success')
    window.location.reload()
  }

  const handleChange = (value) => {
    //
    setSelectedOrg(value)
    getFocusAreas(value)
  }

  const showModal = () => {
    if (!selectedOrg) {
      alert('Please select one first')
      return
    }
    setCreateFocusAreaModal(true)
  }

  const getMyInfo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/admin/me`)

      // Check the admin organizations
      if (response?.data?.user?.organizations && response?.data?.user?.organizations.length > 0) {
        const org = response.data.user.organizations
        // console.log(org)

        // store to render at JSX
        org.forEach((element, idx) => {
          if (idx == 0) handleChange(element._id) // auto selecting the first organization to fetch some results
          setOrganizations((prevElements) => [
            ...prevElements,
            {
              value: element._id,
              label: element.name,
            },
          ])
        })
      }

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getFocusAreas = async (v) => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/focus/getFocusAreas?organizationId=${v || selectedOrg}`)

      console.log(response.data.focusAreas)
      setOrgFocusAreas(response.data.focusAreas)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const createEvent = async () => {
    try {
      //   console.log(form)
      // Check if any field in the form is empty
      const isEmptyField = Object.entries(form).some(([key, value]) => {
        // Check if it's a string and empty after trimming
        if (typeof value === 'string') {
          return value.trim() === ''
        }
        // Check if it's a number and equals 0 or undefined
        if (typeof value === 'number') {
          return value === 0
        }
        // For any other types (optional, depending on your form structure)
        return !value
      })

      if (isEmptyField || !selectedOrg) {
        message.error('Please fill in all the fields.')
        return
      }

      const payload = {
        ...form,
        organization: selectedOrg,
        category: 'priorities', // Just adding as default
        level: 2, // That means its not at Create event page for users ( not sure if its searchable or not, if yes, prevent search result )
        clubEvent: true,
        disabled: false, // to show it immediately
      }

      // console.log(payload)

      // return

      const response = await axiosInstance.post(`/api/v1/focus/createSubFocus?focusId=${focusArea}`, payload)

      // const response = await axiosInstance.post(`/api/v1/clubEvents/createClubEvent`, payload) // ! OLD
      // console.log('Event created successfully', response.data)
      message.success('Event created successfully.')

      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const editSubFocus = async () => {
    try {
      setLoading(true)
      const payload = {
        ...form,
        focusId: focusArea,
      }
      const response = await axiosInstance.put(`/api/v1/focus/editSubFocus?subFocusId=${editingId}`, payload)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getData = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/focus/focusItems?organization=${selectedOrg}&clubEvent=true&sendDisabled=true`)
      // console.log(response.data?.focusItems)
      setData(response.data?.focusItems)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyInfo()
  }, [])

  useEffect(() => {
    if (selectedOrg) {
      getData()
    }
  }, [selectedOrg])

  return (
    <Layout>
      <div>Club Events</div>
      {createFocusAreaModal && (
        <Modal
          title="Create an event"
          open={createFocusAreaModal}
          footer={null}
          maskClosable={false}
          onCancel={() => {
            setCreateFocusAreaModal(false)
            // to clear form when closing Modal
            setForm({
              subTitle: '',
              quickDescription: '',
              points: 0,
              link: '',
              dateTime: '',
            })
            setIsUpdating(false)
            setFocusArea('')
          }}
        >
          <Input placeholder="Enter the title" value={form.subTitle} style={{ marginBottom: 10 }} onChange={(e) => onChange({ subTitle: e.target.value })} />
          <Input placeholder="Link" value={form.link} onChange={(e) => onChange({ link: e.target.value })} />
          <Input
            placeholder="Description"
            value={form.quickDescription}
            onChange={(e) => onChange({ quickDescription: e.target.value })}
            style={{ marginTop: 10, width: '100%' }}
          />
          <InputNumber
            placeholder="Points (Max:10000,  Min:1)"
            min={1}
            max={10000}
            value={form.points ? form.points : ''}
            onChange={(e) => onChange({ points: e })} // sending integer
            style={{ marginTop: 10, width: '100%' }}
          />
          <Select
            placeholder="Choose focus area"
            style={{
              width: `100%`,
              marginTop: 10,
            }}
            value={focusArea ? focusArea : undefined}
            onChange={(value) => setFocusArea(value)}
            options={focusAreas.map((el) => ({ label: el.fullForm, value: el._id }))}
          />
          <DatePicker
            showTime
            use12Hours
            showNow={false}
            style={{ marginTop: 10 }}
            defaultValue={form.dateTime ? dayjs(form.dateTime) : undefined}
            onChange={(value, dateString) => {
              // console.log('Selected Time: ', value)
              // console.log('Formatted Selected Time: ', dateString)
            }}
            onOk={onOk}
          />
          {/* // todo: select Club on subit */}
          {/* // todo: select community event by default (Maybe on accpet, when mixing with 'events' collection) not sure about. */}
          <br />
          <br />
          {!isUpdating ? (
            <Button type="primary" onClick={createEvent} loading={loading} style={{ marginTop: 10 }}>
              Save
            </Button>
          ) : (
            <Button type="primary" onClick={editSubFocus} loading={loading} style={{ marginTop: 10 }}>
              Save edit
            </Button>
          )}
        </Modal>
      )}

      {organizations.length > 0 && (
        <div>
          <p>Choose organization for which the Event is for</p>
          <Select
            placeholder="Please select club or business"
            style={{
              width: 250,
              marginRight: 10,
            }}
            defaultValue={organizations[0].value}
            onChange={handleChange}
            options={organizations}
          />
          <Button type="primary" onClick={showModal}>
            Create Event
          </Button>
        </div>
      )}
      <Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 300 }} />
    </Layout>
  )
}

export default ClubEvents
