import React, { useState, useRef, useEffect } from 'react'

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Modal, Avatar, Space, Input, InputNumber, Button, Popconfirm, Table, Select, Spin } from 'antd'
import { useSelector } from 'react-redux'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function EditFocusArea() {
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)

  let { parentId, childId } = useParams()
  const [subFocus, setSubFocus] = useState({})
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({})
  const [focusArea, setOrgFocusArea] = useState({})
  const [focusItems, setFocusItems] = useState({})

  const onChange = (obj) => {
    // console.log(obj)
    setForm((prev) => ({ ...prev, ...obj }))
  }

  const getFocusAreas = async (v) => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/focus/getFocusAreaById?focusId=${parentId}`)
      const response2 = await axiosInstance.get(`/api/v1/focus/focusItems?organization=${auth.user.organizations[0]}&parent=${parentId}&sendDisabled=true`)
      const response3 = await axiosInstance.get(`/api/v1/focus/focusItems?focusItemId=${childId}&sendDisabled=true`)

      setOrgFocusArea(response.data.focusArea) // Focus area information like H&F and and full form
      setFocusItems(response2.data.focusItems) // all focus items under this parent Focus area
      setSubFocus(response3.data.focusItems[0]) // single desired focus area (getting this for faster without mapping over response2)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const editSubFocus = async () => {
    try {
      // console.log(form)
      // return

      setLoading(true)

      const response = await axiosInstance.put(`/api/v1/focus/editSubFocus?focusId=${parentId}&subFocusId=${childId}`, form)

      // console.log(response.data)

      navigate(`/focusArea/${parentId}`) // todo: revert

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getFocusAreas()
  }, [])

  return (
    <Layout>
      <div>
        <Button
          onClick={() => {
            navigate(`/focusArea/${parentId}`)
          }}
        >
          Back
        </Button>
        <h2>Edit Sub focus</h2>
        {/* {JSON.stringify(subFocus)} */}
        {loading && <Spin />}
        {Object.keys(subFocus).length > 0 && (
          <div>
            <span>Category: {subFocus.category.charAt(0).toUpperCase() + subFocus.category.slice(1)}</span>
            <br />
            <span>Sub title</span>
            <Input placeholder="Sub title" defaultValue={subFocus.subTitle} onChange={(e) => onChange({ subTitle: e.target.value })} style={{ marginTop: 10 }} />
            <div style={{ marginTop: 15 }} />
            <span>Points</span>
            <br />
            <InputNumber placeholder="Points" min={1} max={100000} defaultValue={subFocus.points} onChange={(e) => onChange({ points: e })} style={{ marginTop: 10 }} />
            <div style={{ marginTop: 15 }} />
            <span>Quick Description</span>
            <Input
              placeholder="Quick Description"
              defaultValue={subFocus.quickDescription}
              onChange={(e) => onChange({ quickDescription: e.target.value })}
              style={{ marginTop: 10 }}
            />

            <div style={{ marginTop: 15 }} />
            <span>Category</span>
            <Select
              placeholder="Choose category"
              defaultValue={subFocus.category}
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ category: value })}
              options={[
                {
                  value: 'priorities',
                  label: 'Priorities',
                },
                {
                  value: 'habits',
                  label: 'Habits',
                },
                {
                  value: 'challenges',
                  label: 'Challenges',
                },
                {
                  value: 'goals',
                  label: 'Goals',
                },
              ]}
            />

            <div style={{ marginTop: 15 }} />
            <span>Level</span>
            <Select
              placeholder="Choose level"
              defaultValue={subFocus.level}
              style={{
                width: `100%`,
                marginTop: 10,
              }}
              onChange={(value) => onChange({ level: value })}
              options={[
                {
                  value: 1,
                  label: 'Everyone',
                },
                {
                  value: 2,
                  label: 'Admin',
                },
              ]}
            />

            {subFocus?.category == 'habits' && (
              <>
                <div style={{ marginTop: 15 }} />
                <span>Mandatory priorities</span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Mandatory priorities"
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.habitsPriorities?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ habitsPriorities: value })}
                  options={focusItems
                    .filter((el) => el.category == 'priorities')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
                <div style={{ marginTop: 15 }} />
                <span>Optional priorities</span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Optional priorities"
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.optionalPriorities?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ optionalPriorities: value })}
                  options={focusItems
                    .filter((el) => el.category == 'priorities')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
              </>
            )}

            {subFocus.category == 'challenges' && (
              <>
                <div style={{ marginTop: 15 }} />
                <span>Mandatory habits</span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Mandatory habits"
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.mandatoryHabits?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ mandatoryHabits: value })}
                  options={focusItems
                    .filter((el) => el.category == 'habits')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
                <div style={{ marginTop: 15 }} />
                <span>Choose optional habits </span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Choose optional habits  "
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.optionalHabits?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ optionalHabits: value })}
                  options={focusItems
                    .filter((el) => el.category == 'habits')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
                <div style={{ marginTop: 15 }} />
                <span>Mandatory priorities </span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Mandatory priorities  "
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.habitsPriorities?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ habitsPriorities: value })}
                  options={focusItems
                    .filter((el) => el.category == 'priorities')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
                <div style={{ marginTop: 15 }} />
                <span>Choose optional priorities </span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Choose optional priorities  "
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.optionalPriorities?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ optionalPriorities: value })}
                  options={focusItems
                    .filter((el) => el.category == 'priorities')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
              </>
            )}

            {subFocus.category == 'goals' && (
              <>
                <div style={{ marginTop: 15 }} />
                <span>Mandatory challenges</span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Mandatory challenges"
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.mandatoryChallenges?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ mandatoryChallenges: value })}
                  options={focusItems
                    .filter((el) => el.category == 'challenges')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
                <div style={{ marginTop: 15 }} />
                <span>Optional challenges</span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Optional challenges"
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.optionalChallenges?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ optionalChallenges: value })}
                  options={focusItems
                    .filter((el) => el.category == 'challenges')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
                <div style={{ marginTop: 15 }} />
                <span>Mandatory habits </span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Mandatory habits"
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.mandatoryHabits?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ mandatoryHabits: value })}
                  options={focusItems
                    .filter((el) => el.category == 'habits')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
                <div style={{ marginTop: 15 }} />
                <span>Optional habits</span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Optional habits"
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.optionalHabits?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ optionalHabits: value })}
                  options={focusItems
                    .filter((el) => el.category == 'habits')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
                <div style={{ marginTop: 15 }} />
                <span>Mandatory priorities</span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Mandatory priorities"
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.habitsPriorities?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ habitsPriorities: value })}
                  options={focusItems
                    .filter((el) => el.category == 'priorities')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
                <div style={{ marginTop: 15 }} />
                <span>Optional priorities</span>
                <Select
                  mode="multiple"
                  optionFilterProp="label"
                  placeholder="Optional priorities"
                  style={{
                    width: `100%`,
                    marginTop: 10,
                  }}
                  defaultValue={focusItems
                    ?.filter((el) => subFocus.optionalPriorities?.some((obj) => obj._id === el._id))
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                  onChange={(value) => onChange({ optionalPriorities: value })}
                  options={focusItems
                    .filter((el) => el.category == 'priorities')
                    .map((el) => {
                      return {
                        label: el.subTitle,
                        value: el._id,
                      }
                    })}
                />
              </>
            )}
          </div>
        )}

        <Button
          style={{ marginTop: 10 }}
          type="primary"
          onClick={() => {
            //
            editSubFocus()
          }}
        >
          Update
        </Button>
      </div>
    </Layout>
  )
}

export default EditFocusArea
