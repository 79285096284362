import React, { useState, useEffect } from 'react'
import { Button, Spin, Modal } from 'antd'

import { useDispatch } from 'react-redux'
import { authslice } from '../store/auth/authSlice'
import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function Home() {
  const { logout } = authslice.actions
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [connectedUsers, setConnectedUsers] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const getOnlinePeoples = async (v) => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(`/api/v1/logs/currentOnlinePeoples`)
      setConnectedUsers(response.data?.users || [])
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getOnlinePeoples()
  }, [])

  return (
    <Layout>
      <div>
        <Modal title={`Users online ${connectedUsers.length}`} open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(!isModalOpen)}>
          <p>Click on name to view profile</p>
          {connectedUsers.map((el, idx) => {
            return (
              <p style={{ fontSize: '1.2rem', margin: 0, marginTop: 2 }}>
                <a href={`/member/${el.userId}`} target="_blank">
                  {el.firstName} {el.lastName} <span style={{ fontSize: 9 }}>({el.signupType == 'growdieCode' ? 'Grow or Die, Atlanta' : 'Digital Community'})</span>
                </a>
              </p>
            )
          })}
        </Modal>

        {loading && <Spin />}
        <p onClick={() => setIsModalOpen(!isModalOpen)}>Total online in app (Excluding admins): {connectedUsers.length}</p>

        <Button
          danger
          type="link"
          onClick={() => {
            dispatch(logout())
          }}
        >
          Logout
        </Button>
      </div>
    </Layout>
  )
}

export default Home
