import React, { useEffect, useState } from 'react'
import { DatePicker, Select, Button, Table, Input } from 'antd'

import Layout from '../../components/layout/Layout'
import axiosInstance from '../../utils/axiosInstance'

const { RangePicker, Avatar } = DatePicker

const timeZones = [
  {
    value: '-5',
    label: '-5',
  },
  {
    value: '-6',
    label: '-6',
  },
  {
    value: '+5',
    label: '+5',
  },
  {
    value: '+6',
    label: '+6',
  },
]

const columns = [
  {
    title: 'First Name',
    key: 'firstName',
    render: (data) => <div>{data.firstName}</div>,
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    title: 'Last Name',
    key: 'lastName',
    render: (data) => <div>{data.lastName}</div>,
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
  },
  {
    title: 'PER Act. Count',
    key: 'PER',
    render: (data) => <div>{data.counts.PER}</div>,
    sorter: (a, b) => (a.counts?.PER || 0) - (b.counts?.PER || 0),
  },
  {
    title: 'PRF Act. Count',
    key: 'PRF',
    render: (data) => <div>{data.counts.PRF}</div>,
    sorter: (a, b) => (a.counts?.PRF || 0) - (b.counts?.PRF || 0),
  },
  {
    title: 'PRP Act. Count',
    key: 'PRP',
    render: (data) => <div>{data.counts.PRP}</div>,
    sorter: (a, b) => (a.counts?.PRP || 0) - (b.counts?.PRP || 0),
  },
  {
    title: 'PLX Act. Count',
    key: 'PLX',
    render: (data) => <div>{data.counts.PLX}</div>,
    sorter: (a, b) => (a.counts?.PLX || 0) - (b.counts?.PLX || 0),
  },
  {
    title: 'H&F Act. Count',
    key: 'H&F',
    render: (data) => <div>{data.counts['H&F']}</div>,
    sorter: (a, b) => (a.counts['H&F'] || 0) - (b.counts['H&F'] || 0),
  },
  {
    title: 'FIH Act. Count',
    key: 'FIH',
    render: (data) => <div>{data.counts.FIH}</div>,
    sorter: (a, b) => (a.counts?.FIH || 0) - (b.counts?.FIH || 0),
  },
  {
    title: 'MES Act. Count',
    key: 'MES',
    render: (data) => <div>{data.counts.MES}</div>,
    sorter: (a, b) => (a.counts?.MES || 0) - (b.counts?.MES || 0),
  },
]

function FocusAreasPage() {
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [organizations, setOrganizations] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')
  const [timeZone, setTimeZone] = useState(timeZones[0].value)
  const [data, setData] = useState(null)
  const [calculations, setCalculations] = useState(null)

  const getMyInfo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/admin/me`)

      // Check the admin organizations
      if (response?.data?.user?.organizations && response?.data?.user?.organizations.length > 0) {
        const org = response.data.user.organizations
        // console.log(org)

        // store to render at JSX
        org.forEach((element, idx) => {
          if (idx == 0) setSelectedOrg(element._id) // auto selecting the first organization to fetch some results
          setOrganizations((prevElements) => [
            ...prevElements,
            {
              value: element._id,
              label: element.name,
            },
          ])
        })
      }

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getData = async () => {
    try {
      if (!startDate || !endDate) {
        console.log(startDate, endDate)
        return
      }

      setLoading(true)

      const payload = {
        timeZone,
        startDate,
        endDate,
        summaryOfClub: selectedOrg,
      }

      console.log(payload)

      const response = await axiosInstance.post(`/api/v1/metrics/focusAreasPage`, payload)
      setCalculations(response.data?.calculations)

      //   console.log(response.data)
      setData(response.data?.results)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleRangePicker = (value) => {
    // console.log('Selected Time: ', value)
    value?.map((el, idx) => {
      // console.log(moment(new Date(el)).format('MMMM DD, YYYY h:mm:ss a')) // Readable format
      // console.log(new Date(el).getTime()) // Epoch timestamp in miliseconds
      let start = 0
      let end = 0
      if (idx == 0) {
        start = new Date(el).getTime()
        setStartDate(start)
      } else if (idx == 1) {
        end = new Date(el).getTime()
        setEndDate(end)
      }
      console.log(start)
      console.log(end)
    })
  }

  useEffect(() => {
    getMyInfo()
  }, [])

  if (organizations.length == 0) return null

  return (
    <Layout>
      <h1>Focus Areas</h1>
      <RangePicker onChange={handleRangePicker} />
      <Select
        placeholder="Please select club or business"
        style={{ width: 250 }}
        defaultValue={organizations[0].value}
        onChange={(value) => setSelectedOrg(value)}
        options={organizations}
      />
      <Select placeholder="Please select timezone" style={{ width: 100 }} defaultValue={timeZones[0].value} onChange={(value) => setTimeZone(value)} options={timeZones} />

      <Button type="primary" style={{ cursor: 'pointer' }} onClick={getData} loading={loading}>
        Get data
      </Button>
      <div style={{ marginTop: 10 }}></div>
      {calculations && (
        <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
          {data?.length > 0 && <p>Total results: {data?.length} </p>}
          {Object.entries(calculations.grandCounts).map(([key, value]) => (
            <p key={key}>
              {key}: {value}
            </p>
          ))}
        </div>
      )}

      <Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 300 }} />
    </Layout>
  )
}

export default FocusAreasPage
