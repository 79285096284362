import React, { useState, useRef, useEffect } from 'react'
import { Table, Spin, Avatar, Button, Card } from 'antd'
import { useNavigate, useLocation } from 'react-router'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function Metrics() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [dataTwo, setDataTwo] = useState(null)

  const getData = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/metrics/checkGoogleBucketSize`)

      setData(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  // for total number of completed activities and their points
  const getDataTwo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/metrics/totalActivitiesAndPoints`)

      setDataTwo(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const makeBackup = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(`/api/v1/admin/downloadDbv3`, { s: 'onlyweKnow.2025' })
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    // getData()
  }, [])

  return (
    <Layout>
      <div style={{ padding: '20px' }}>
        <h2>Metrics</h2>
        {loading && <Spin />}
        <hr />
        <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
          <a href={`/metrics/summarypage`} target="_blank" style={{ color: '#1677ff', cursor: 'pointer' }}>
            <Card style={{ cursor: 'pointer', maxWidth: 300 }}>
              <p>Summary page</p>
            </Card>
          </a>
          <a href={`/metrics/activitiesPage`} target="_blank" style={{ color: '#1677ff', cursor: 'pointer' }}>
            <Card style={{ cursor: 'pointer', maxWidth: 300 }}>
              <p>Activities page</p>
            </Card>
          </a>
          <a href={`/metrics/memberActivities`} target="_blank" style={{ color: '#1677ff', cursor: 'pointer' }}>
            <Card style={{ cursor: 'pointer', maxWidth: 300 }}>
              <p>Member activites</p>
            </Card>
          </a>
          <a href={`/metrics/proveIt`} target="_blank" style={{ color: '#1677ff', cursor: 'pointer' }}>
            <Card style={{ cursor: 'pointer', maxWidth: 300 }}>
              <p>Prove it</p>
            </Card>
          </a>
          <a href={`/metrics/focusAreasPage`} target="_blank" style={{ color: '#1677ff', cursor: 'pointer' }}>
            <Card style={{ cursor: 'pointer', maxWidth: 300 }}>
              <p>Focus Areas</p>
            </Card>
          </a>
          <a href={`/metrics/engagementPage`} target="_blank" style={{ color: '#1677ff', cursor: 'pointer' }}>
            <Card style={{ cursor: 'pointer', maxWidth: 300 }}>
              <p>Engagement Page</p>
            </Card>
          </a>
        </div>

        <hr />
        <Button type="default" style={{ cursor: 'pointer' }} onClick={getDataTwo} loading={loading}>
          Get total activities
        </Button>
        <p>& their points (All time)</p>
        {dataTwo && (
          <p>
            Total completed activites {dataTwo?.totalCompletedEvents} for {dataTwo?.totalPoints} points.
          </p>
        )}
        <hr />
        {loading ? (
          <div>
            <p>Please wait this may need sometime, depends on size of bucket and google response.</p>
          </div>
        ) : (
          <>
            <p style={{ fontSize: '1rem' }}>Result {data?.sizeString}</p>
            <Button type="default" style={{ cursor: 'pointer' }} onClick={getData}>
              Get bucket size
            </Button>
            <p style={{ color: 'red' }}>
              Increased bucket size will cost more money each month, but it also depends on various things such as number of operations at bucket, trash of bucket etc. Please
              contact admin for proper billing each month.
            </p>
          </>
        )}
        <hr />
        <Button type="default" style={{ cursor: 'pointer' }} onClick={makeBackup}>
          Make backup
        </Button>
        <p>This will help to keep backup of database at storage server. Try to do it atleast once or few times per day.</p>
      </div>
    </Layout>
  )
}

export default Metrics
