import React, { useState, useEffect } from 'react'
import { Modal, Avatar, Space, Input, InputNumber, Button, Popconfirm, Table, Select, Spin } from 'antd'
import moment from 'moment'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function AnEventDetails() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  let { eventId } = useParams()

  const getDataFunc = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/focus/anEvent?id=${eventId}`)

      console.log(response.data)

      setData(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleChange = async (value) => {
    const response = await axiosInstance.post(`/api/v1/privacy/hideAFeedPost`, {
      eventId,
      disabled: value,
    })
  }

  useEffect(() => {
    getDataFunc()
  }, [])

  return (
    <Layout>
      {loading && <Spin />}
      {data && (
        <div>
          <p style={{ fontWeight: 'bold' }}>The event: </p>
          <p>{data.theEvent.subTitle}</p>
          <p>{data.theEvent.category}</p>

          <p style={{ fontWeight: 'bold' }}>The feed post: </p>
          <Select
            defaultValue={data.event.disabled} // because some of them don't have any disabled field so they if you see at ui no defaultValue selected then consider them as Enabled
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: false, // means disabled false
                label: 'Enabled',
              },
              {
                value: true,
                label: 'Disabled',
              },
            ]}
          />
          <p>
            {data.event.dynamicTextFor} : {data.event.dynamicText}
          </p>
          <p>Prove it text: {data.event.proveItText}</p>
          <p>Status: {data.event.status}</p>
          <p>Posted at: {moment(data.event.updatedAt).format('MMMM DD, YYYY h:mm:ss a')}</p>
          <p>Rating: {data.event.ratingFive}</p>
          <p>
            <a href={`/member/${data.event?.createdBy}`} target="_blank">
              Posted by
            </a>
          </p>

          <div style={{ display: 'flex', overflowX: 'scroll', marginBottom: '10px' }}>
            {data.event.uploadedResponses &&
              data.event.uploadedResponses.map((itemAttachment, idx) => {
                const contentType = itemAttachment.contentType.split('/')[0]
                const style = {
                  width: 300,
                  height: 'auto',
                  objectFit: 'cover',
                  marginRight: '10px',
                }

                if (contentType === 'image') {
                  return (
                    <button key={idx} style={{ border: 'none', background: 'none', padding: 0 }}>
                      <img src={itemAttachment.mediaLink} alt={`media-${idx}`} style={style} />
                    </button>
                  )
                } else if (contentType === 'video') {
                  return <video key={idx} style={style} src={itemAttachment.mediaLink} controls />
                } else {
                  return (
                    <span key={idx} style={{ marginRight: '10px' }}>
                      Unknown type
                    </span>
                  )
                }
              })}
          </div>
        </div>
      )}
    </Layout>
  )
}

export default AnEventDetails
