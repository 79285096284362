import React, { useState, useEffect } from 'react'
import { Table, Spin } from 'antd'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function ReportedCelebrationPosts() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const getReportedPostsFeedFunc = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/privacy/getReportedPostsCelebration`)

      setData(response.data.reportedPosts)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getReportedPostsFeedFunc()
  }, [])

  const columns = [
    {
      title: 'label (Changeable)',
      dataIndex: 'label',
      key: 'label',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => <div>{record.label}</div>,
    },
    {
      title: 'Code (Parmanent)',
      dataIndex: 'code',
      key: 'code',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => <div>{record.code}</div>,
    },
    {
      title: 'Reported by',
      dataIndex: 'user',
      key: 'user',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => (
        <div>
          <a href={`/member/${record.createdByUser?._id}`} target="_blank">
            {record.createdByUser?.firstName}
          </a>
        </div>
      ),
    },
    {
      title: 'Reported item',
      key: 'reportedItem',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => (
        <div>
          <a href={`/anCelebrationDetails/${record.celebration?._id}`} target="_blank">
            Open
          </a>
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString(),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ]

  return (
    <Layout>
      <div style={{ padding: '20px' }}>
        <h2>Reports feed post</h2>
        {loading ? (
          <Spin />
        ) : (
          <>
            <p>Count: {data.length}</p>
            <Table columns={columns} dataSource={data} pagination={{ pageSize: 5 }} scroll={{ x: 300 }} />
          </>
        )}
      </div>
    </Layout>
  )
}

export default ReportedCelebrationPosts
