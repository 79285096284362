import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DatePicker, Select, Button, Table } from 'antd'

import Layout from '../../components/layout/Layout'
import axiosInstance from '../../utils/axiosInstance'

const { RangePicker, Avatar } = DatePicker

const timeZones = [
  {
    value: '-5',
    label: '-5',
  },
  {
    value: '-6',
    label: '-6',
  },
  {
    value: '+5',
    label: '+5',
  },
  {
    value: '+6',
    label: '+6',
  },
]

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
  },
  {
    title: 'Total points',
    dataIndex: 'totalPoints',
    key: 'totalPoints',
    sorter: (a, b) => a.totalPoints - b.totalPoints,
  },
  {
    title: 'Total Activities',
    dataIndex: 'totalActivities',
    key: 'totalActivities',
    sorter: (a, b) => a.totalActivities - b.totalActivities,
  },
  {
    title: 'Lifetime Points',
    dataIndex: 'lifeTimePoints',
    key: 'lifeTimePoints',
    sorter: (a, b) => a.lifeTimePoints - b.lifeTimePoints,
  },
  // TODO: after "totalPointsGrowthLevel" accepted by Ike, remove this "lifeTimeGrowthLevel"
  // {
  //   title: 'Lifetime Growth Level',
  //   dataIndex: 'lifeTimeGrowthLevel',
  //   key: 'lifeTimeGrowthLevel',
  // },
  {
    title: 'Growth Level',
    dataIndex: 'totalPointsGrowthLevel',
    key: 'totalPointsGrowthLevel',
    sorter: (a, b) => a.totalPoints - b.totalPoints,
  },
]

function SummaryPage() {
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [organizations, setOrganizations] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')
  const [timeZone, setTimeZone] = useState(timeZones[0].value)
  const [data, setData] = useState(null)
  const [calculations, setCalculations] = useState(null)

  const getMyInfo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/admin/me`)

      // Check the admin organizations
      if (response?.data?.user?.organizations && response?.data?.user?.organizations.length > 0) {
        const org = response.data.user.organizations
        // console.log(org)

        // store to render at JSX
        org.forEach((element, idx) => {
          if (idx == 0) setSelectedOrg(element._id) // auto selecting the first organization to fetch some results
          setOrganizations((prevElements) => [
            ...prevElements,
            {
              value: element._id,
              label: element.name,
            },
          ])
        })
      }

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getData = async () => {
    try {
      if (!startDate || !endDate) {
        console.log(startDate, endDate)
        return
      }

      setLoading(true)

      const payload = {
        timeZone,
        startDate,
        endDate,
        summaryOfClub: selectedOrg,
        focusType: 'all',
      }

      const response = await axiosInstance.post(`/api/v1/metrics/summaryPage`, payload)

      //   console.log(response.data)
      setData(response.data?.results)
      setCalculations(response.data?.calculations)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleRangePicker = (value) => {
    // console.log('Selected Time: ', value)
    value?.map((el, idx) => {
      // console.log(moment(new Date(el)).format('MMMM DD, YYYY h:mm:ss a')) // Readable format
      // console.log(new Date(el).getTime()) // Epoch timestamp in miliseconds
      let start = 0
      let end = 0
      if (idx == 0) {
        start = new Date(el).getTime()
        setStartDate(start)
      } else if (idx == 1) {
        end = new Date(el).getTime()
        setEndDate(end)
      }
      console.log(start)
      console.log(end)
    })
  }

  useEffect(() => {
    getMyInfo()
  }, [])

  if (organizations.length == 0) return null

  return (
    <Layout>
      <h1>Summary Page</h1>
      <RangePicker onChange={handleRangePicker} />
      <Select
        placeholder="Please select club or business"
        style={{ width: 250 }}
        defaultValue={organizations[0].value}
        onChange={(value) => setSelectedOrg(value)}
        options={organizations}
      />
      <Select placeholder="Please select timezone" style={{ width: 100 }} defaultValue={timeZones[0].value} onChange={(value) => setTimeZone(value)} options={timeZones} />
      <Button type="primary" style={{ cursor: 'pointer' }} onClick={getData} loading={loading}>
        Get data
      </Button>
      <div style={{ marginTop: 10 }}></div>
      {calculations && (
        <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
          {data?.length > 0 && <p>Total results: {data?.length} </p>}
          <p>Grand Total Points: {calculations.grandTotalPoints} </p>
          <p>Grand Total Activities: {calculations.grandTotalActivities} </p>
          <p>Grand Lifetime Points {calculations.grandLifeTimePoints} </p>
        </div>
      )}
      <Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 300 }} />
    </Layout>
  )
}

export default SummaryPage
