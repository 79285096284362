import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DatePicker, Select, Button, Table, Input } from 'antd'

import Layout from '../../components/layout/Layout'
import axiosInstance from '../../utils/axiosInstance'

const { RangePicker, Avatar } = DatePicker

const timeZones = [
  {
    value: '-5',
    label: '-5',
  },
  {
    value: '-6',
    label: '-6',
  },
  {
    value: '+5',
    label: '+5',
  },
  {
    value: '+6',
    label: '+6',
  },
]

const columns = [
  {
    title: 'First Name',
    key: 'firstName',
    render: (data) => <div>{data.createdBy.firstName}</div>,
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    title: 'Last Name',
    key: 'lastName',
    render: (data) => <div>{data.createdBy.lastName}</div>,
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
  },
  {
    title: 'Date',
    key: 'dateTime',
    render: (data) => <div>{moment(data?.dateTime).format('MM/DD')}</div>,
  },
  {
    title: 'Activity',
    key: 'theEventItem',
    render: (data) => <div>{data.theEventItem.subTitle}</div>,
  },
  {
    title: 'Points',
    key: 'points',
    render: (data) => <div>{data.theEventItem.points}</div>,
    sorter: (a, b) => a.points - b.points,
  },
  {
    title: 'Category',
    key: 'category',
    render: (data) => <div>{data.theEventItem.category}</div>,
    sorter: (a, b) => a.category.localeCompare(b.category),
  },
  {
    title: 'Area',
    key: 'focusArea',
    render: (data) => <div>{data.parent.title}</div>,
    sorter: (a, b) => a.focusArea.localeCompare(b.focusArea),
  },
  {
    title: 'Attachments',
    key: 'attachments',
    render: (data) => (
      <div>
        {data.uploadedResponses.map((el) => (
          <a href={el} target="_blank">
            Download{' '}
          </a>
        ))}
      </div>
    ),
  },
  {
    title: 'Location',
    key: 'location',
    render: (data) => <div>{data?.location?.address?.[0]?.city}</div>,
  },
  {
    title: 'Text',
    dataIndex: 'proveItText',
    key: 'proveItText',
  },
  {
    title: 'Data menu',
    dataIndex: 'dynamicText',
    key: 'dynamicText',
  },
  {
    title: 'Tagged members',
    key: 'taggedMembers',
    render: (data) => (
      <div>
        {data.taggedFriendsDetails.map((el) => (
          <>{`${el.firstName} ${el.lastName} ▪️`}</>
        ))}
      </div>
    ),
  },
]

function ProveIt() {
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [organizations, setOrganizations] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')
  const [timeZone, setTimeZone] = useState(timeZones[0].value)
  const [hourBlock, setHourBlock] = useState('')
  const [category, setCategory] = useState('')
  const [selectedFocusArea, setSelectedFocusArea] = useState('')
  const [activities, setActivities] = useState([])
  const [selectedActivity, setSelectedActivity] = useState('') // id of the Focus Item
  const [data, setData] = useState(null)

  const getMyInfo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/admin/me`)

      // Check the admin organizations
      if (response?.data?.user?.organizations && response?.data?.user?.organizations.length > 0) {
        const org = response.data.user.organizations
        // console.log(org)

        // store to render at JSX
        org.forEach((element, idx) => {
          if (idx == 0) setSelectedOrg(element._id) // auto selecting the first organization to fetch some results
          setOrganizations((prevElements) => [
            ...prevElements,
            {
              value: element._id,
              label: element.name,
            },
          ])
        })
      }

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getData = async () => {
    try {
      if (!startDate || !endDate) {
        console.log(startDate, endDate)
        return
      }

      setLoading(true)

      const payload = {
        timeZone,
        startDate,
        endDate,
        summaryOfClub: selectedOrg,
        ...(selectedActivity ? { selectedActivity } : {}), // e.g. subtitle
      }

      console.log(payload)

      const response = await axiosInstance.post(`/api/v1/metrics/proveItPage`, payload)

      //   console.log(response.data)
      setData(response.data?.tasks)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleRangePicker = (value) => {
    // console.log('Selected Time: ', value)
    value?.map((el, idx) => {
      // console.log(moment(new Date(el)).format('MMMM DD, YYYY h:mm:ss a')) // Readable format
      // console.log(new Date(el).getTime()) // Epoch timestamp in miliseconds
      let start = 0
      let end = 0
      if (idx == 0) {
        start = new Date(el).getTime()
        setStartDate(start)
      } else if (idx == 1) {
        end = new Date(el).getTime()
        setEndDate(end)
      }
      console.log(start)
      console.log(end)
    })
  }

  const handleSearch = async (val) => {
    if (!val.trim()) {
      return
    }

    try {
      setLoading(true)
      const response = await axiosInstance.post('/api/v1/search/globalSearch', {
        query: val,
      })
      console.log('bye')
      console.log('Success', `Results: ${JSON.stringify(response.data)}`)
      // setActivities(response.data.searchResults)
      const items = response.data.searchResults.map((elem) => {
        return {
          label: `(${elem.category}) ${elem.subTitle} `,
          value: elem._id,
        }
      })
      console.log(items)
      setActivities(items)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getMyInfo()
  }, [])

  if (organizations.length == 0) return null

  return (
    <Layout>
      <h1>Prove it</h1>
      <RangePicker onChange={handleRangePicker} />
      <Select
        placeholder="Please select club or business"
        style={{ width: 250 }}
        defaultValue={organizations[0].value}
        onChange={(value) => setSelectedOrg(value)}
        options={organizations}
      />
      <Select placeholder="Please select timezone" style={{ width: 100 }} defaultValue={timeZones[0].value} onChange={(value) => setTimeZone(value)} options={timeZones} />

      {/* <Select style={{ width: 300 }} showSearch filterOption={false} onSearch={handleSearch} options={activities} onChange={(value) => setSelectedActivity(value)} /> */}

      <Button type="primary" style={{ cursor: 'pointer' }} onClick={getData} loading={loading}>
        Get data
      </Button>
      <div style={{ marginTop: 10 }}></div>
      <Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 300 }} />
    </Layout>
  )
}

export default ProveIt
